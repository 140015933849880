export function getHomeRoute() {
    return '/';
}

export function getRulesPageRoute() {
    return '/instrukcja';
}

export function getGamePageRoute() {
    return '/game';
}

export function getPolicyRoute() {
    return '/polityka';
}
export function getRide(){
    return '/zaczynamy'
}

